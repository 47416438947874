import React from 'react';
import { Alert, theme, Popconfirm, message, Space, Dropdown, Tooltip, Select, Row, Col, Form, Modal } from 'antd';
import TSTable from '../components/common/TSTable';
import { DeleteOutlined, EditOutlined, PlusCircleOutlined, EyeOutlined, PrinterOutlined, FileExcelOutlined, FilePdfOutlined } from '@ant-design/icons';
import { SECONDARY_COLOR } from '../constants/color';
import { DELETE, GET } from '../frameworks/HttpClient';
import ColorButton from '../components/common/ColorButton';
import DeliveryModal from '../components/delivery/DeliveryModal';
import { URL_DELIVERY, URL_REPORT } from '../constants/urls';
import { useNavigate } from 'react-router-dom';
import { PATH } from '../routes/CustomRoutes';
import { dateTimeToHumanString, formatComma } from '../frameworks/Util';
import { DATETIME_FORMAT, DATE_FORMAT, HUMAN_DATETIME_FORMAT, HUMAN_DATE_FORMAT } from '../constants/string';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import moment from 'moment';

export default function DeliveryListView() {
  const navigate = useNavigate();
  const [openDELModal, setOpenDELModal] = React.useState(false);
  const [targetDEL, setTargetDEL] = React.useState(null);
  const tableRef = React.useRef(null);
  const [loadings, setLoadings] = React.useState([]);
  const [filterStatus, setFilterStatus] = React.useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const { t } = useTranslation();
  const {
    token: { colorWarning, colorInfo }
  } = theme.useToken();

const handleDocumentDEL = async (index ,value, e) => {
    try{
      setLoadings((state) => {
        const newLoadings = [...state];
        newLoadings[index] = true;
        return newLoadings;
      });
      const response = await GET(`${URL_REPORT.DEL_FACTORY_REPORT}?del001=${value}&export=pdf`);
      window.open(response.requestUrl);
    }
      catch{
      }
      finally{
        setLoadings((state) => {
          const newLoadings = [...state];
          newLoadings[index] = false;
          return newLoadings;
        });
    }
  };

    const exportTable = async(type) => {
    if(type == "pdf"){
      await tableRef.current.exportPDF({
        title: t("delivery_list"),
        subtitle: `${t("data_retrieval_date")} ${dayjs().format(HUMAN_DATETIME_FORMAT)}`,
        landscape: true
      })
    } else {
      await tableRef.current.exportXlsx({
        title: t("delivery_list"),
        subtitle: `${t("data_retrieval_date")} ${dayjs().format(HUMAN_DATETIME_FORMAT)}`,
        filename: `${t("delivery_list")} - ${dayjs().format(DATETIME_FORMAT)}.xlsx`
      })
  }}

  const handleDelete = async (id) => {
    try {
      await DELETE(`${URL_DELIVERY.DELIVERY_TRANSACTION}${id}/`)
      refreshData();
      messageApi.success('ลบข้อมูลสำเร็จ')
    } catch (error) {
      messageApi.error(error.errorMessages)
    }
  }

  const refreshData = () => {
    if (tableRef && tableRef.current) {
      tableRef.current.fetch();
    }
  }

  const items = [
    {
      key: 'CC',
      label: t("delivery.del", { type : "CC"}),
    },
    {
      key: 'Factory',
      label: t("delivery.del", { type : ("Factory")}),
    },
  ]
  const columns = [
    {
      title: t("delivery.del_code"),
      dataIndex: 'code',
      sorter: true,
      searcher: true,
    },
    {
      title: t("delivery.lot_ref"),
      dataIndex: 'stocks_code',
    },
    {
      title: t("factory"),
      dataIndex: 'receiver_name',
      sorter: true,
      searcher: true,
    },
    {
      title: t("collecting_center"),
      dataIndex: 'sender_name',
      sorter: true,
      searcher: true,
    },
    {
      title: t("product"),
      dataIndex: 'product_name',
      width: '30%',
      sorter: true,
      searcher: true,
    },
    {
      title: t("factory_del.factory_arrival_date"),
      dataIndex: 'receive_date',
      render: (value) => dayjs(value, DATE_FORMAT).format(HUMAN_DATE_FORMAT)
    },
    {
      title: t("weight"),
      dataIndex: 'receive_weight',
      render: (value) => `${formatComma(value)}`
    },
    {
      title: t("delivery.weight_dry"),
      dataIndex: 'dry_weight',
      render: (value) => `${formatComma(value)}`
    },
    {
      title: t("delivery.RI"),
      dataIndex: 'ri_no',
      render: (value) => value ? value : '-'
    },
    {
      title: t("delivery.ACFC"),
      dataIndex: 'acfl_no',
      render: (value) => value ? value : '-'
    },
    // {
    //   title: t("delivery.net_import"),
    //   dataIndex: 'in_weight',
    //   render: (value) => `${formatComma(value)}`
    // },
    // {
    //   title: t("delivery.net_export"),
    //   dataIndex: 'out_weight',
    //   render: (value) => `${formatComma(value)}`
    // },
    {
      title: 'Action',
      width: 140,
      dataIndex: 'id',
      skipReport: true, 
      render: (value, original, index) => {  
        return (
          <div style={{textAlign: 'center'}}>
            <Space direction='horizontal'>
            {contextHolder}
            <Tooltip title={t("edit")}>
              <ColorButton 
                type='primary'
                override={colorWarning}
                icon={<EditOutlined />}
                onClick={() => navigate(`${PATH.DELIVERY_ORDER}/${value}`)}/>
                </Tooltip>
                <Tooltip title={t("delivery.print_del")}>
                <Dropdown
                  menu={{
                    items: items,
                    onClick: (e) => handleDocumentDEL(index,value,e.key)
                  }}
                  placement="bottom"
                  >
                <ColorButton
                type='primary'
                override={colorInfo}
                loading={loadings[index]}
                onClick={() => handleDocumentDEL(index, value)}
                  icon={<PrinterOutlined />}
                >
                </ColorButton>
                </Dropdown>
              </Tooltip>
              <Tooltip title={t("delete")}>
              <Popconfirm
                title='ยืนยันการยกเลิกใบส่งยางหรือไม่?'
                onConfirm={() => handleDelete(value)}
                okText={t("confirm")}
                okButtonProps={{ danger: true }}
                cancelText={t("cancel")}>
                <ColorButton 
                  type='primary' 
                  danger
                  icon={<DeleteOutlined />}/>
              </Popconfirm>
              </Tooltip>
            </Space>
          </div>
        )
      }
    }
  ]

  React.useEffect(() => {
    refreshData();
  }, [filterStatus]);

  return (
    <div>
      {contextHolder}
      <Row>
        <Col md={6} sm={24} xs={24}>
          <Form.Item label={t("status")}>
            <Select
              style={{width: "100%"}}
              value={filterStatus}
              onChange={value => setFilterStatus(value)}
              options={[
                { value: '', label: t("all") },
                { value: 'sent=true', label: t("delivery.sent") },
                { value: 'sent=false', label: t('delivery.not_yet_sent') },
              ]}/>
          </Form.Item>
        </Col>
        <Col md={18} sm={24} xs={24} style={{textAlign: "right"}}>
          <ColorButton 
            type='primary' 
            override={SECONDARY_COLOR}
            icon={<PlusCircleOutlined />} 
            style={{float: 'right', marginBottom: '1rem', marginLeft: 10}}
            onClick={() => setOpenDELModal(true)}>
              {t("delivery.add_del")}
          </ColorButton>
          <ColorButton
            icon={<FileExcelOutlined />}
            style={{float: "right",  marginLeft : 8 }}
            onClick={() => exportTable("excel")}>
            Excel
          </ColorButton>
          <ColorButton
            icon={<FilePdfOutlined />}
            style={{float: "right",}}
            onClick={() => exportTable("pdf")}>
            PDF
          </ColorButton>
        </Col>
      </Row>

      <TSTable 
        ref={tableRef}
        columns={columns}
        url={`${URL_DELIVERY.DELIVERY_TRANSACTION}?${filterStatus}`}
        rowKey={'id'}
        size={'small'}
        pageSize={10}/>

      <DeliveryModal 
        open={openDELModal || (targetDEL != null)}
        data={targetDEL}
        onClose={() => {
          if (openDELModal)
            setOpenDELModal(false)

          if (targetDEL)
            setTargetDEL(null);
        }}
        onUpdated={() => {
          if (openDELModal)
            setOpenDELModal(false)

          if (targetDEL)
            setTargetDEL(null);

          refreshData();
        }}/>
    </div>
  )
}
